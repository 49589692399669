
import React, { useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "./ui/dialog";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { Mail, Loader2, Check } from "lucide-react";
import { toast } from "sonner";
import { CleanupAction } from "@/types/database";
import { supabase } from "@/integrations/supabase/client";
import { formatDate } from "@/hooks/useUpcomingActions";

interface ActionInscriptionDialogProps {
  action: CleanupAction;
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

const ActionInscriptionDialog = ({ action, isOpen, onOpenChange }: ActionInscriptionDialogProps) => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const validateEmail = (email: string): boolean => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!email.trim()) {
      toast.error("Veuillez saisir une adresse email");
      return;
    }
    
    if (!validateEmail(email)) {
      toast.error("Veuillez saisir une adresse email valide");
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      console.log("Envoi de la requête à la fonction register-to-action");
      console.log("Données envoyées:", {
        email,
        actionId: action.id,
        actionTitle: action.title,
        actionDate: action.date,
        actionLocation: action.location,
        actionTime: `${action.startTime} - ${action.endTime}`
      });
      
      const { data, error } = await supabase.functions.invoke(
        "register-to-action",
        {
          body: { 
            email,
            actionId: action.id,
            actionTitle: action.title,
            actionDate: action.date,
            actionLocation: action.location,
            actionTime: `${action.startTime} - ${action.endTime}`
          },
        }
      );

      if (error) {
        console.error("Erreur lors de l'appel de la fonction:", error);
        throw new Error(error.message || "Une erreur est survenue lors de l'appel de la fonction");
      }

      console.log("Réponse reçue:", data);
      
      if (!data.success) {
        throw new Error(data.error || "Une erreur est survenue lors de l'inscription");
      }

      // Gestion des avertissements (par exemple, email non envoyé)
      if (data.warning) {
        console.warn("Avertissement:", data.warning);
        toast.warning(`Inscription réussie, mais ${data.warning}`);
      } else {
        toast.success("Inscription réussie!");
      }
      
      setIsSubscribed(true);
      
      // Reset after 3 seconds
      setTimeout(() => {
        setIsSubscribed(false);
        onOpenChange(false);
        setEmail("");
      }, 3000);
      
    } catch (error: any) {
      console.error("Erreur:", error);
      toast.error(`L'inscription a échoué: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>S'inscrire à l'action</DialogTitle>
          <DialogDescription>
            {action.title} - {formatDate(action.date)}
          </DialogDescription>
        </DialogHeader>
        
        {isSubscribed ? (
          <div className="py-6 flex flex-col items-center text-center space-y-4">
            <div className="h-12 w-12 rounded-full bg-green-100 flex items-center justify-center">
              <Check className="h-6 w-6 text-green-600" />
            </div>
            <div>
              <h3 className="text-lg font-medium">Inscription confirmée !</h3>
              <p className="text-sm text-muted-foreground mt-1">
                Un email de confirmation a été envoyé à {email}
              </p>
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4 py-4">
            <div className="space-y-2">
              <p className="text-sm">
                Veuillez renseigner votre email pour vous inscrire à cette action. 
                Nous vous enverrons un email de confirmation avec les détails.
              </p>
            </div>
            <div className="flex items-center gap-2">
              <Input
                type="email"
                placeholder="votre@email.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="flex-1"
              />
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? (
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                ) : (
                  <Mail className="mr-2 h-4 w-4" />
                )}
                S'inscrire
              </Button>
            </div>
            <p className="text-xs text-muted-foreground">
              En vous inscrivant, vous acceptez de recevoir des emails concernant cette action, 
              notamment en cas d'annulation ou de modification.
            </p>
          </form>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ActionInscriptionDialog;
