
import React, { useState } from "react";
import { MapPin, Calendar as CalendarIcon, Clock } from "lucide-react";
import { format, parseISO } from "date-fns";
import { fr } from "date-fns/locale";
import { CleanupAction } from "@/types/database";
import ActionInscriptionDialog from "../ActionInscriptionDialog";

interface ActionCardProps {
  action: CleanupAction;
}

const ActionCard = ({ action }: ActionCardProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  
  const formatDisplayDate = (date: string) => {
    return format(parseISO(date), 'd MMMM yyyy', { locale: fr });
  };

  const convertStatus = (status: string): 'upcoming' | 'past' => {
    if (status === 'completed' || status === 'cancelled') return 'past';
    return 'upcoming';
  };

  const isPast = convertStatus(action.status) === 'past';

  const getActionTypeDisplay = (type: string) => {
    switch (type) {
      case 'cleanup': return 'Nettoyage';
      case 'workshop': return 'Atelier';
      case 'awareness': return 'Sensibilisation';
      default: return type;
    }
  };

  const handleInscriptionClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsDialogOpen(true);
  };

  return (
    <div className="group rounded-xl overflow-hidden shadow-md transition-all duration-500 hover:shadow-xl">
      <div className="relative h-52 overflow-hidden">
        <img 
          src={action.imageUrl || '/placeholder.svg'} 
          alt={action.title} 
          className="w-full h-full object-cover transition-transform duration-1000 group-hover:scale-105"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent"></div>
        <div className="absolute bottom-4 left-4 right-4">
          <span className="inline-block px-2 py-1 text-xs font-medium bg-green-700 text-white rounded mb-2">
            {getActionTypeDisplay(action.type)}
          </span>
          <h3 className="text-xl font-bold text-white">{action.title}</h3>
        </div>
      </div>
      <div className="p-6">
        <div className="space-y-3 mb-4">
          <div className="flex items-center text-sm text-gray-600">
            <MapPin className="w-4 h-4 mr-2 text-green-700" />
            <span>{action.location}</span>
          </div>
          <div className="flex items-center text-sm text-gray-600">
            <CalendarIcon className="w-4 h-4 mr-2 text-green-700" />
            <span>{formatDisplayDate(action.date)}</span>
          </div>
          <div className="flex items-center text-sm text-gray-600">
            <Clock className="w-4 h-4 mr-2 text-green-700" />
            <span>
              {`${action.startTime} - ${action.endTime}`}
              {action.distance && ` • ${action.distance}`}
              {action.difficulty && ` • ${action.difficulty}`}
              {action.elevation && ` • ↑ ${action.elevation}`}
            </span>
          </div>
          {isPast && (
            <div className="flex items-center justify-between text-sm mt-2 pt-2 border-t border-gray-100">
              <span className="text-gray-600">
                <strong>{action.currentParticipants}</strong> participants
              </span>
              <span className="text-gray-600">
                <strong>{action.wasteData?.totalWeight || 0}</strong> kg collectés
              </span>
            </div>
          )}
        </div>
        {!isPast ? (
          <div className="flex justify-between items-center">
            <span className="text-sm text-green-700 font-medium">
              {action.currentParticipants}/{action.maxParticipants} participants
            </span>
            <button
              onClick={handleInscriptionClick}
              className="px-3 py-1 border border-green-700 text-green-700 rounded hover:bg-green-700 hover:text-white transition-colors"
            >
              S'inscrire
            </button>
          </div>
        ) : (
          <div className="flex justify-end">
            <span className="px-3 py-1 border border-gray-300 text-gray-400 rounded cursor-not-allowed">
              Terminée
            </span>
          </div>
        )}
      </div>
      
      <ActionInscriptionDialog 
        action={action}
        isOpen={isDialogOpen}
        onOpenChange={setIsDialogOpen}
      />
    </div>
  );
};

export default ActionCard;
