
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Calendar, Clock, MapPin } from "lucide-react";
import { CleanupAction } from "@/types/database";
import { formatDate, getTypeDisplay, getDifficultyLabel } from "@/hooks/useUpcomingActions";
import ActionInscriptionDialog from "./ActionInscriptionDialog";

interface ActionCardProps {
  action: CleanupAction;
}

const ActionCard = ({ action }: ActionCardProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  // Check if the action is in the past
  const isPast = action.status === 'completed' || action.status === 'cancelled';

  const handleInscriptionClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsDialogOpen(true);
  };

  return (
    <div className="group rounded-xl overflow-hidden shadow-md transition-all duration-500 hover:shadow-xl">
      <div className="relative h-64 overflow-hidden">
        <img 
          src={action.imageUrl || "https://images.unsplash.com/photo-1567095761054-7a02e69e5c43"} 
          alt={action.title} 
          className="w-full h-full object-cover transition-transform duration-1000 group-hover:scale-105"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent"></div>
        <div className="absolute bottom-4 left-4 right-4">
          <span className="inline-block px-2 py-1 text-xs font-medium bg-green-700 text-white rounded mb-2">
            {getTypeDisplay(action.type)}
          </span>
          <h3 className="text-xl font-bold text-white">{action.title}</h3>
        </div>
      </div>
      <div className="p-6">
        <div className="space-y-3 mb-4">
          <div className="flex items-center text-sm text-gray-600">
            <MapPin className="w-4 h-4 mr-2 text-green-700" />
            <span>{action.location}</span>
          </div>
          <div className="flex items-center text-sm text-gray-600">
            <Calendar className="w-4 h-4 mr-2 text-green-700" />
            <span>{formatDate(action.date)}</span>
          </div>
          <div className="flex items-center text-sm text-gray-600">
            <Clock className="w-4 h-4 mr-2 text-green-700" />
            <span>
              {action.duration && `${action.duration} • `}
              {action.distance && `${action.distance} • `}
              {action.difficulty && `${getDifficultyLabel(action.difficulty)}`}
              {action.elevation && ` • ↑ ${action.elevation}`}
            </span>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-sm text-green-700 font-medium">Places limitées</span>
          {!isPast ? (
            <button 
              onClick={handleInscriptionClick}
              className="px-3 py-1 border border-green-700 text-green-700 rounded hover:bg-green-700 hover:text-white transition-colors"
            >
              S'inscrire
            </button>
          ) : (
            <span className="px-3 py-1 border border-gray-300 text-gray-400 rounded cursor-not-allowed">
              Terminée
            </span>
          )}
        </div>
      </div>

      <ActionInscriptionDialog 
        action={action}
        isOpen={isDialogOpen}
        onOpenChange={setIsDialogOpen}
      />
    </div>
  );
};

export default ActionCard;
